<template>

	<layout :include_header="true">

		<template v-slot:header-center>

			<main_nav />
			
			<site_nav v-if="auth_user && site" :site="site" :preview="preview" :download="download" />

		</template>

		<template v-slot:header-end>

			<support_link />

		</template>

		<template v-slot:main-body>
			
			<div class="container h-100 py-4">
				
				<div class="row h-100">

					<div class="col-12">

						<loader v-if="site_loading" />

						<div v-else-if="site">

							<div class="row h-100 justify-content-center">

								<div class="col-12 col-md-10 col-lg-6 col-xxl-5">

									<div class="card">

										<div class="card-body">

											<form @submit.prevent="submit">

												<p class="alert alert-danger">
													This action can't be reversed so please be sure before proceeding. Deleting your site will remove all associated files and database.
												</p>

												<p v-if="error.message" class="alert alert-danger text-start">
													{{ error.message }}
												</p>

												<p>The site reference is: {{ site.slug }}</p>

												<form_control
													label="Enter site reference"
													type="password"
													name="password"
													:placeholder="site.slug"
													:required="true"
													:error="error"
													v-model="password"
												/>

												<btn_submit 
													:label="'Delete ' + site.name + ' site'" 
													icon="cancel"  
													:icon_after="true" 
													:loading="loading" 
												/>

											</form>

										</div>

									</div>

								</div>

							</div>

						</div>

					</div>

				</div>

			</div>

		</template>

	</layout>

</template>

<script>
import { mapGetters } from 'vuex';
import layout from '@/components/layout'
import loader from '@/components/loader'
import main_nav from '@/components/main_nav'
import form_control from '@/components/form_control'
import btn_submit from '@/components/btn_submit'
import support_link from '@/components/support_link'
import site_nav from '@/components/site_nav'
import site_service from '@/services/site_service'
import workspace_service from '@/services/workspace_service'

export default {
	name: 'workspaces',

	components: {
		layout,
		loader,
		main_nav,
		form_control,
		btn_submit,
		support_link,
		site_nav,
	},

	data() {
		return {
			loading: false,
			site_loading: true,
			site: null,
			password: null,
			error: {}
		}
	},

	computed: {

		...mapGetters( 'user', ['auth_user']),

		preview()
		{
			var url = 'https://'

			if ( this.workspace ) {
				url += this.workspace.domain + '/storage/'
			}

			if ( this.site ) {
				url += this.site.slug + '/'
			}

			return url
		},

		download()
		{
			var url = 'https://'

			if ( this.workspace ) {
				url += this.workspace.domain + '/download/'
			}

			if ( this.site ) {
				url += this.site.slug + '/'
			}

			return url
		},

	},

	mounted()
	{
		this.get_workspace()

		this.get_site()
	},

	methods: {
		reset_error()
		{
			this.error = {}
		},

		async get_site()
		{
			this.reset_error()

			site_service.show( this.$route.params.workspace_id, this.$route.params.site_id ).then(( response ) => {
				this.site = response.data
			}).catch( ( error ) => {
				this.error = error
			}).finally(() => {
				this.site_loading = false
			})
		},

		async get_workspace()
		{
			this.reset_error()

			workspace_service.show( this.$route.params.workspace_id ).then(( response ) => {
				this.workspace = response.data
			}).catch( ( error ) => {
				this.error = error
			}).finally(() => {
				this.workspace_loading = false
			})
		},

		async submit()
		{
			this.loading = true 

			this.reset_error()

			site_service.destroy( this.$route.params.workspace_id, this.$route.params.site_id, {
				password: this.password
			}).then(() => {
				this.$router.push( { name: 'workspaces' } )
			}).catch( ( error ) => {
				this.error = error
			}).finally(() => {
				this.loading = false
			})
		}
	}
}
</script>

<style>

</style>
